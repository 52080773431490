import React, { useEffect } from 'react'
import "./comingSoon.scss"
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'

function ComingSoon() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
        <Header />
        <section className='comingSoonWrap'>
            <div className='container'>
                <h2>Coming Soon</h2>
            </div>
        </section>
        <Footer />
    </>
  )
}

export default ComingSoon
