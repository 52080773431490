import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './../../assets/images/logo.png';
import './header.scss'
import { useLocation, useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';


function Header() {
  // const [isScrolled, setIsScrolled] = useState(false);
  // const [selectedLink, setSelectedLink] = useState('home');
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const navigate = useNavigate()
  const handleLinkClick = (link) => {
    setSelectedLink(link);
    // console.log("selectedLink============",selectedLink)
  };
  const getSelectedLink = () => {
    switch (location.pathname) {
      case '/':
        return 'home';
      case '/treatment':
        return 'treatments';
      case '/about-us':
        return 'about';
      case '/meet-the-team':
        return 'team';
      case '/shop':
        return 'shop';
      case '/contact-us':
        return 'contact';
      default:
        return 'home';
    }
  };
  const [selectedLink, setSelectedLink] = useState(getSelectedLink());
  return (
    <>
      {windowWidth > 991 ? (
    <Navbar className={"custom-navbar"}>
      <Container>
        <div className="custom-navbar-wrap">
            <Nav className="">
              <Nav.Link
                className={`custom-nav-link ${selectedLink === 'home' ? 'selected' : ''}`}
                onClick={() => {
                  navigate("/")
                }}
              >
                Home
              </Nav.Link>
              <Nav.Link
                className={`custom-nav-link ${selectedLink === 'treatments' ? 'selected' : ''}`}
                onClick={() => {
                  navigate("/treatment")
                }}
              >
                Treatments
              </Nav.Link>
              <Nav.Link
                className={`custom-nav-link ${selectedLink === 'about' ? 'selected' : ''}`}
                onClick={() => {
                  navigate("/about-us")
                }}
              >
                About us
              </Nav.Link>
            </Nav>
            <Navbar.Brand onClick={() => {
                  navigate("/")
                }}><img src={logo} alt="Logo" /></Navbar.Brand>
            {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
            <Nav className="">
              <Nav.Link
                className={`custom-nav-link ${selectedLink === 'team' ? 'selected' : ''}`}
                onClick={() => {
                  navigate("/meet-the-team")
                }}
              >
                Meet the team
              </Nav.Link>
              <Nav.Link
                className={`custom-nav-link ${selectedLink === 'shop' ? 'selected' : ''}`}
                onClick={() => {
                  navigate("/shop")
                }}
              >
                Shop
              </Nav.Link>
              <Nav.Link
                className={`custom-nav-link ${selectedLink === 'contact' ? 'selected' : ''}`}
                to="/contact-us"
                onClick={() => {
                  navigate("/contact-us")
                }}
              >
                Contact us
              </Nav.Link>
            </Nav>
        </div>
      </Container>
    </Navbar>
        ) : (
          <>
            {[false].map((expand) => (
              <Navbar key={expand} expand={expand} className="custom-navbar">
                <Container fluid>
                <Navbar.Brand onClick={() => {
                  navigate("/")
                }}><img src={logo} alt="Logo" /></Navbar.Brand>
                  <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                  >
                    <Offcanvas.Header closeButton>
                      {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                        Offcanvas
                      </Offcanvas.Title> */}
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav className="justify-content-end flex-grow-1 pe-3">
                        <Nav.Link
                          className={`custom-nav-link ${selectedLink === 'home' ? 'selected' : ''}`}
                          onClick={() => {
                            navigate("/")
                          }}
                        >
                          Home
                        </Nav.Link>
                        <Nav.Link
                          className={`custom-nav-link ${selectedLink === 'treatments' ? 'selected' : ''}`}
                          onClick={() => {
                            navigate("/treatment")
                          }}
                        >
                          Treatments
                        </Nav.Link>
                        <Nav.Link
                          className={`custom-nav-link ${selectedLink === 'about' ? 'selected' : ''}`}
                          onClick={() => {
                            navigate("/about-us")
                          }}
                        >
                          About us
                        </Nav.Link>
                        <Nav.Link
                          className={`custom-nav-link ${selectedLink === 'team' ? 'selected' : ''}`}
                          onClick={() => {
                            navigate("/meet-the-team")
                          }}
                        >
                          Meet the team
                        </Nav.Link>
                        <Nav.Link
                          className={`custom-nav-link ${selectedLink === 'shop' ? 'selected' : ''}`}
                          onClick={() => navigate('/shop')}
                        >
                          Shop
                        </Nav.Link>
                        <Nav.Link
                          className={`custom-nav-link ${selectedLink === 'contact' ? 'selected' : ''}`}
                          to="/contact-us"
                          onClick={() => {
                            navigate("/contact-us")
                          }}
                        >
                          Contact us
                        </Nav.Link>
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Container>
              </Navbar>
            ))}
          </>
        )}
    </>
  )
}

export default Header
