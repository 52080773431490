import React, { useEffect } from 'react'
import SecHeading from '../../components/secHeading/SecHeading'
import PageHeading from '../../components/breadcrumbHeading/PageHeading'
import faqImg1 from '../../assets/images/faqImg1.png'
import faqImg2 from '../../assets/images/faqImg2.png'
import faqImg3 from '../../assets/images/faqImg3.png'
import './faq.scss'
import Accordion from 'react-bootstrap/Accordion';
// import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import SecondaryButton from '../../components/commonButton/SecondaryButton'

function FaqMicro() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accordionData = [
        {
            id: 0,
            title: 'What skin conditions can Microneedling treat?',
            ulContent: `Medical Microneedling, also referred to as Collagen Induction Therapy, works for a variety of skin conditions, including:`,
            listContent: [
                'Treatment of scars (including burn scars, acne scars, and surgical scars)',
                'Stretch marks (including post-pregnancy)',
                'Wrinkles and fine lines',
                'Pigmentation marks',
            ],
            content1: `Microneedling produces channels in the dermis which stimulate the body’s natural defenses to repair the skin. This leads to the production of concentrated amounts of collagen and proteins to the treated areas of the skin. This results in the skin's thickness being increased by up to 80%, smoothing scars, visibly reducing stretch marks, and erasing wrinkles.`
        },
        {
            id: 1,
            title: 'What is the benefit of Microneedling?',
            content: `The procedure improves skin texture, reduces the appearance of scars, and creates a more youthful tone. It’s no surprise that this treatment has been hailed as a non-invasive way to achieve youthful, dewy-looking skin.`
        },
        {
            id: 2,
            title: 'Do I need a consultation before Microneedling treatment?',
            content: `A consultation appointment is a requirement for this treatment.`
        },
        {
            id: 3,
            title: 'What is Microneedling?',
            content: `Microneedling stimulates the production of new collagen and elastin in the skin by puncturing the dermis with microscopic needles. The treatment utilizes the skin's ability to heal itself, in turn triggering the production of new collagen and elastin. In addition to treating pores, fine wrinkles, loss of elasticity, scarring, and pigmentation, it also enables topical products to penetrate deeper into the skin, increasing their effectiveness.`
        },
    ];
  return (
    <>
    <Header />
    <section className='faq'>
        <div className='container'>
            <div style={{marginTop:"0px",display:"flex",justifyContent:"space-between", alignItems: "center"}}>
                <PageHeading pageTitle={'Microneedling'} />
                <SecondaryButton  btnLink={"treatment?category=Microneedling"} btnName={"Book a treatment "} />
            </div>
            <div className='faq-banner'>
                <div className='row'>
                    <div className='col-md-7'>
                        <div className='box'>
                            <img src={faqImg1} />
                        </div>
                    </div>
                    <div className='col-md-5 mt-4 mt-md-0'>
                        <div className='box'>
                            <img src={faqImg2} />
                            <img src={faqImg3} />
                        </div>
                    </div>
                </div>
                <p>
                Injectable aesthetic treatments are advanced, non-surgical solutions designed to rejuvenate and enhance the appearance of the face. Commonly used injectables include botulinum toxin (Botox), which relaxes facial muscles to reduce the appearance of fine lines and wrinkles, and dermal fillers, which restore volume, contour facial features, and smooth deep creases. These treatments are minimally invasive, offering quick results with little discomfort and no significant recovery time. Ideal for individuals seeking a refreshed, youthful look, injectables provide customizable and natural-looking outcomes tailored to each person's unique facial structure and goals.
                </p>
            </div>
            <SecHeading HeadingText={'FAQ'} />
            <div className='faq-accordion'>
                {/* <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h4>Accordion Item #1</h4> <IoIosArrowDown /></Accordion.Header>
                        <Accordion.Body>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                        </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h4>Accordion Item #1</h4> <IoIosArrowDown /></Accordion.Header>
                        <Accordion.Body>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.
                        </p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion> */}

                <Accordion>
                    {accordionData.map(item => (
                        <Accordion.Item eventKey={item.id.toString()} key={item.id}>
                            <Accordion.Header>
                                <h4>{item.title}</h4>
                                {/* <IoIosArrowDown /> */}
                                <IoIosArrowUp />
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>{item.content}</p>
                                <ul>
                                    <span>{item.ulContent}</span>
                                    {item.listContent && item.listContent.map((liItem, index) => (
                                        <li key={index}>{liItem}</li>
                                    ))}
                                </ul>
                                <p>{item.content1}</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </div>
    <div className='dream-box'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-md-6'>
                                <h4>Dreaming your perfect look ?</h4>
                                <p>Reserve your spot for a rejuvenating aesthetic treatment</p>
                            </div>
                            <div className='col-md-6 d-flex justify-content-end'>
                                <SecondaryButton btnLink={"treatment?category=Microneedling"} btnName={"Book a treatment "} />
                            </div>
                        </div>
                    </div>
                </div>
    </section>
    <Footer />
    </>
  ) 
}

export default FaqMicro
