import './App.scss';
import { Helmet } from 'react-helmet';
import { Routes, BrowserRouter as Router, Route, useNavigate, useLocation } from 'react-router-dom';
import { aboutUsPage, comingSoonPage, contactUsPage, faqMicroPage, faqPage, indexPage, meetTeamPage, termAndConditionPage, treatmentPage } from './Routes';
import HomePage from './pages/Home/HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutUs from './pages/aboutUs/AboutUs';
import MeetTeam from './pages/meetTeam/MeetTeam';
import ContactUs from './pages/contactUs/ContactUs';
import Treatment from './pages/treatment/Treatment';
import TermAndCondition from './pages/termsAndCondition/TermAndCondition';
import FaqInjectables from './pages/faq/FaqInjectables';
import FaqMicro from './pages/faq/FaqMicro';
import { v4 as uuidv4 } from 'uuid';
import ToastNotification from './components/toast/ToastNotification';
import ComingSoon from './pages/comingSoon/ComingSoon';

export function generateRandomString() {
	return uuidv4();
}

if (sessionStorage.getItem('sessionString') === null) {
	sessionStorage.setItem('sessionString', generateRandomString())
}

const routerParams = {};

if (process.env.PUBLIC_URL) {
  routerParams['basename'] = process.env.PUBLIC_URL;
}

function App() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Router {...routerParams}>
        <Routes>
          <Route exact path={indexPage} element={<HomePage />} />
          <Route exact path={aboutUsPage} element={<AboutUs />} />
          <Route exact path={meetTeamPage} element={<MeetTeam />} />
          <Route exact path={contactUsPage} element={<ContactUs />} />
          <Route exact path={treatmentPage} element={<Treatment />} />
          <Route exact path={termAndConditionPage} element={<TermAndCondition />} />
          <Route exact path={faqPage} element={<FaqInjectables />} />
          <Route exact path={faqMicroPage} element={<FaqMicro />} />
          <Route exact path={comingSoonPage} element={<ComingSoon />} />
        </Routes>
      </Router>
      <ToastNotification />
    </>

  );
}

export default App;